import {request} from './request';


// 萤石云获取token
export function getEzuikitAccessToken(){
  return request({
    url:'/api/v1/camera/getezuikitaccesstoken',
  })
}

