<template>

	<div class="module">
		<div class="caption">
			<p>园区占比</p>
		</div>
		<br>
			
		<div class="pan">				
			<ul>
				<li>			
					<div class="pan_chart" ref="chart1">
					</div>
				</li>
				<li>					
					<div class="pan_chart" ref="chart2">
					</div>
				</li>
				<li>			
					<div class="pan_chart" ref="chart3">
					</div>
				</li>
				<li>					
					<div class="pan_chart" ref="chart4">
					</div>
				</li>
			</ul>
		</div>

	</div>
  
</template>




<script>
import * as Echarts from 'echarts';
import { getYqgh } from '../../network/yqgh';

export default {

    data() 
	{ 
		return { 
			timer: null,		// 定时器
			yqData: [
				{
					"id": "0",
					"name": "核心区",
					"target": "综合生产服务配套协同创新基地",
					"area": "14.08",
					"quantity": "167",
					"people": "43100",
					"turnover": "640.9",
					"detail": ""
				},
				{
					"id": "1",
					"name": "新涵园",
					"target": "“产学研用”一体化电子信息产业基地",
					"area": "1.4",
					"quantity": "10",
					"people": "460",
					"turnover": "44.97",
					"detail": ""
				},
				{
					"id": "2",
					"name": "城北园",
					"target": "高端装备制造产业基地",
					"area": "4.57",
					"quantity": "12",
					"people": "3120",
					"turnover": "43.06",
					"detail": ""
				},
				{
					"id": "3",
					"name": "食品园",
					"target": "绿色工业旅游食品产业基地",
					"area": "9.21",
					"quantity": "2",
					"people": "1910",
					"turnover": "51.74",
					"detail": ""
				},
				{
					"id": "4",
					"name": "滨海园",
					"target": "军民融合示范基地",
					"area": "16.31",
					"quantity": "3",
					"people": "460",
					"turnover": "0.63",
					"detail": ""
				}
			],					// 园区数据
			
			chart1: null,
			option1: null,

			chart2: null,
			option2: null,

			chart3: null,
			option3: null,

			chart4: null,
			option4: null,
		} 	
	}, 
	
	mounted() {
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*3600)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {

			//1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			this.chart2 = Echarts.init(this.$refs.chart2);	
			this.chart3 = Echarts.init(this.$refs.chart3);	
			this.chart4 = Echarts.init(this.$refs.chart4);	

			//2.配置数据
			this.option1 = {			
				title: {
					text: "   园区面积 \n（平方公里）",
					x: "110px",
					y: "80px",
					textStyle: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 14
					}
				},
				color: ["#FF5D3B", "#9BF5ED", "#65FF94", "#5D7092", "#EB9200"],
				tooltip: {
					trigger: "item",
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [{
					name: "园区面积",
					type: "pie",
					// radius: "55%",
            		radius: ['40%', '70%'],
					center: ["60%", "45%"],
					startAngle: 355, //起始角度
					itemStyle: {
						normal: {
                			borderRadius: 2,
							label: {
								show: !0,
								formatter: function(e) {
									return "" !== e.name ? e.value: ""
								}
							},
							labelLine: {
								length: 5,
								show: !0,
								color: "#00ffff"
							}
						}
					},
					data: [{
						value: 14.09,
						name: "核心区",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#FF7E3B'},
									{offset: 1, color: '#FF5D3B'}
								],
							}
						},
					},
					{
						value: 1.4,
						name: "新涵园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#93FFE5'},
									{offset: 1, color: '#00CCF7'}
								],
							}
						},
					},
					{
						value: 4.57,
						name: "城北园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#3BDC7D'},
									{offset: 1, color: '#1BD643'}
								],
							}
						},
					},
					{
						value: 9.21,
						name: "食品园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#5D7092'},
									{offset: 1, color: '#2D4062'}
								],
							}
						},
					},
					{
						// value: 16.32,
						value: 0,
						name: "滨海园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#F7C400'},
									{offset: 1, color: '#EB9200'}
								],
							}
						},
					}],
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: "rgba(0, 0, 0, 0.5)"
						}
					}
				}]
			};
			
			this.option2 = {				
				title: {
					text: "企业数量\n  （家）",
					x: "110px",
					y: "85px",
					textStyle: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 14
					}
				},
				color: ["#FF5D3B", "#9BF5ED", "#65FF94", "#5D7092", "#EB9200"],
				tooltip: {
					trigger: "item",
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [{
					name: "企业数量",
					type: "pie",
            		radius: ['40%', '70%'],
					center: ["55%", "45%"],
					itemStyle: {
						normal: {
                			borderRadius: 2,
							label: {
								show: !0,
								formatter: function(e) {
									return "" !== e.name ? e.value: ""
								}
							},
							labelLine: {
								length: 5,
								show: !0,
								color: "#00ffff"
							}
						}
					},
					data: [{
						value: 167,
						name: "核心区",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#FF7E3B'},
									{offset: 1, color: '#FF5D3B'}
								],
							}
						},
					},
					{
						value: 10,
						name: "新涵园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#93FFE5'},
									{offset: 1, color: '#00CCF7'}
								],
							}
						},
					},
					{
						value: 12,
						name: "城北园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#3BDC7D'},
									{offset: 1, color: '#1BD643'}
								],
							}
						},
					},
					{
						value: 3,
						name: "食品园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#5D7092'},
									{offset: 1, color: '#2D4062'}
								],
							}
						},
					},
					{
						value: 3,
						name: "滨海园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#F7C400'},
									{offset: 1, color: '#EB9200'}
								],
							}
						},
					}],
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: "rgba(0, 0, 0, 0.5)"
						}
					}
				}]
			};

			this.option3 = {	

				title: {					
					text: "从业人员\n （人）",
					x: "126px",
					y: "85px",
					textStyle: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 14
					}
				},
				color: ["#FF5D3B", "#9BF5ED", "#65FF94", "#5D7092", "#EB9200"],
				tooltip: {
					trigger: "item",
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [{
					name: "从业人员",
					type: "pie",
            		radius: ['40%', '70%'],
					center: ["60%", "45%"],
					itemStyle: {
						normal: {
                			borderRadius: 2,
							label: {
								show: !0,
								formatter: function(e) {
									return "" !== e.name ? e.value: ""
								}
							},
							labelLine: {
								length: 5,
								show: !0,
								color: "#00ffff"
							}
						}
					},
					data: [{
						value: 43100,
						name: "核心区",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#FF7E3B'},
									{offset: 1, color: '#FF5D3B'}
								],
							}
						},
					},
					{
						value: 460,
						name: "新涵园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#93FFE5'},
									{offset: 1, color: '#00CCF7'}
								],
							}
						},
					},
					{
						value: 3120,
						name: "城北园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#3BDC7D'},
									{offset: 1, color: '#1BD643'}
								],
							}
						},
					},
					{
						value: 1910,
						name: "食品园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#5D7092'},
									{offset: 1, color: '#2D4062'}
								],
							}
						},
					},
					{
						value: 460,
						name: "滨海园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#F7C400'},
									{offset: 1, color: '#EB9200'}
								],
							}
						},
					}],
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: "rgba(0, 0, 0, 0.5)"
						}
					}
				}]
			};

			this.option4 = {	

				title: {					
					text: "园区产值\n（亿元）",
					x: "112px",
					y: "85px",
					textStyle: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 14
					}
				},
				color: ["#FF5D3B", "#9BF5ED", "#65FF94", "#5D7092", "#EB9200"],
				tooltip: {
					trigger: "item",
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				series: [{
					name: "园区产值",
					type: "pie",
            		radius: ['40%', '70%'],
					center: ["55%", "45%"],
					itemStyle: {
						normal: {
                			borderRadius: 2,
							label: {
								show: !0,
								formatter: function(e) {
									return "" !== e.name ? e.value: ""
								}
							},
							labelLine: {
								length: 5,
								show: !0,
								color: "#00ffff"
							}
						}
					},
					data: [{
						value: 640.9,
						name: "核心区",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#FF7E3B'},
									{offset: 1, color: '#FF5D3B'}
								],
							}
						},
					},
					{
						value: 44.97,
						name: "新涵园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#93FFE5'},
									{offset: 1, color: '#00CCF7'}
								],
							}
						},
					},
					{
						value: 43.06,
						name: "城北园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#3BDC7D'},
									{offset: 1, color: '#1BD643'}
								],
							}
						},
					},
					{
						value: 51.74,
						name: "食品园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#5D7092'},
									{offset: 1, color: '#2D4062'}
								],
							}
						},
					},
					{
						value: .63,
						name: "滨海园",
						itemStyle: {
							color: {
								colorStops: [
									{offset: 0, color: '#F7C400'},
									{offset: 1, color: '#EB9200'}
								],
							}
						},
					}],
					emphasis: {
						itemStyle: {
							shadowBlur: 10,
							shadowOffsetX: 0,
							shadowColor: "rgba(0, 0, 0, 0.5)"
						}
					}
				}]
			};

			// 3.传入数据
			this.chart1.setOption(this.option1);
			this.chart2.setOption(this.option2);
			this.chart3.setOption(this.option3);
			this.chart4.setOption(this.option4);


			this.refresh();


			// 选择园区
			var that = this;
			var yqs = document.querySelectorAll(".box3 .yq div")
			console.log(yqs);
			for (var i = 0; i<yqs.length; i++) {
				yqs[i].addEventListener('click', function() {
					
					for (var j = 0; j < yqs.length; j++) {
						yqs[j].className = '';
					}
					this.className = 'currentyq';
											
					var index = this.getAttribute('index');    
					that.$parent.selectyq(index);
					
					that.show();
				})
			}

		},
				
		refresh() {	

			getYqgh().then(res=>{
				this.yqData = res.data;	
								
				for (var i=0; i<=4; i++) {
					this.option1.series[0].data[i].value = res.data[i].area;
					this.option2.series[0].data[i].value = res.data[i].quantity;
					this.option3.series[0].data[i].value = res.data[i].people;
					this.option4.series[0].data[i].value = res.data[i].turnover;
				}
					
				this.show();
			})

		},

		show() {	

			this.chart1.clear();
			this.chart1.setOption(this.option1);

			this.chart2.clear();
			this.chart2.setOption(this.option2);
			
			this.chart3.clear();
			this.chart3.setOption(this.option3);
			
			this.chart4.clear();
			this.chart4.setOption(this.option4);	
		}

	}

}
</script>




<style scoped>


.pan {
	
	height: 120px;
	text-align: left;

	border-radius: 16px;
	/* border-top: 1px solid rgb(21, 219, 245, 0.8); */
	/* border-bottom: 1px solid rgb(21, 219, 245, 0.8); */

}

.question{	
	top: 20px;
	left: 20px;	
	width: 520px;
	
	font-size: 16px;
	color: rgb(255, 255, 255, 1);	
	position: relative;	
	
}
.question span {
	font-size: 18px;
	color: rgb(21, 219, 245);	
}

.answer{	
	top: 30px;
	left: 39px;	
	width: 500px;
	
	font-size: 14px;
	color: rgb(255, 255, 255, 0.8);	
	position: relative;		
}


.pan {	
	/* top: 330px;
	left: 10px; */
	top: 10px;
	left: 0px;
	width: 534px;
	height: 442px;
  	position:relative;
	/* background-color: green; */
}

.pan ul {
	margin: 0;
	/* padding: 14px; */
	left: 0px;

}
.pan li {
	float: left;
	width: 267px;
	height: 221px;
	/* background-color: red; */
}

.pan li p {
	
	top: -27px;
	color: rgb(255, 255, 255, 0.6);
  	font-size: 16px;
  	line-height: 16px;
  	position:relative;
}

.pan_chart {	
	width: 100%;
	height: 100%;
}


</style>