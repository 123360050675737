<template>

	<div class="module">
		<div class="caption">
			<p>园区产业</p>
		</div>
		
		<img id="yqcy_img" :src="require('../../assets/images/yqgh/yqcy_'+img+'.png')" alt="">
	</div>
  
</template>


<script>

export default {

    data() 
	{ 		
		return { 
			img: "0",
		} 	
	}, 

	methods: {

		// 选择园区
		selectyq(index) {			
			this.img = index;
		}
	}

}

</script>


<style scoped>

#yqcy_img {	
	top: 60px;
	left: 20px;
	width: 514px;
	height: 181px;
	position: absolute;			  
}

</style>