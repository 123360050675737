<template>

	<div class="module">
		<div class="caption">
			<p>园区概述</p>
		</div>		
		
		<div class="pan_line1">	
			<div class="bg">	
				<p>- 工业用地 -</p>
			</div>

			<div class="gytd">				
				<p class="gyl_title"><span class="color_1">■ </span>土地供应量</p>	
				<b class="gyl_value">{{gyData.tdgyl}} <span>亩</span></b>
				
				<p class="jj_title"><span class="color_1">■ </span>土地均价</p>	
				<b class="jj_value">{{gyData.tdjj}} <span>万/亩</span></b>
			</div>

		</div>

		
		<div class="pan_line2">	
			<div class="bg">	
				<p>- 商业用地 -</p>
			</div>

			<div class="gytd">				
				<p class="gyl_title"><span class="color_2">■ </span>土地供应量</p>	
				<b class="gyl_value">{{syData.tdgyl}} <span>亩</span></b>
				
				<p class="jj_title"><span class="color_2">■ </span>土地均价</p>	
				<b class="jj_value">{{syData.tdjj}} <span>万/亩</span></b>
			</div>
		</div>


		<div class="caption3">
			<p>惠企配套</p>
		</div>
	
		<div class="table">
			<div class="hr2_1"></div>					
			<div class="hr2_2"></div>					
			<div class="hr3_1"></div>				
			<div class="hr3_2"></div>				
			<div class="hr3_3"></div>
			<ul>
				<li>
					<img src="~@/assets/images/yqgh/i1.png" alt="">
					<p class="day_name">道路</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i2.png" alt="">
					<p class="day_name">给水</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i3.png" alt="">
					<p class="day_name">雨水</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i4.png" alt="">
					<p class="day_name">污水</p>
				</li>
			</ul>
			<ul>
				<li>
					<img src="~@/assets/images/yqgh/i5.png" alt="">
					<p class="day_name">天然气</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i6.png" alt="">
					<p class="day_name">供电</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i7.png" alt="">
					<p class="day_name">蒸汽</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i8.png" alt="">
					<p class="day_name">通讯</p>
				</li>
			</ul>
			<ul>
				<li>
					<img src="~@/assets/images/yqgh/i9.png" alt="">
					<p class="day_name">宽度网络</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i10.png" alt="">
					<p class="day_name">有线电视</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i11.png" alt="">
					<p class="day_name">供热</p>
				</li>
				<li>
					<img src="~@/assets/images/yqgh/i12.png" alt="">
					<p class="day_name">土地平整</p>
				</li>
			</ul>

		</div>

	</div>
  
</template>


<script>
import { getYqgs } from '../../network/yqgh';

export default {

	data() {
		return {
			timer: null,		// 定时器
			gyData: {},		
			syData: {},		
		};
	},

	mounted() {
		this.init();
		this.timer = setInterval(() => {
       		setTimeout(this.refresh(), 0)	   
   		}, 1000*3600)
	},
	beforeDestroy(){
		clearInterval(this.timer);        
		this.timer = null;
	},

	methods: {
		init() {
			this.refresh();
		},

		refresh() {	
			getYqgs().then(res=>{
				this.gyData = res.data[0];
				this.syData = res.data[1];
			})
		}
	}

}

</script>


<style scoped>

.pan_line1 {	
	top: 60px;
	left: 12px;
	width: 262px;
	height: 250px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;	
	  
}
.pan_line1 .bg {
	width: 262px;
	height: 103px;
	position: relative;		
	border-radius:16px 16px 0 0;	
	background: rgba(0, 0, 0, 0.5) url("~@/assets/images/yqgh/ydgy.png") no-repeat; 
}
.bg p {

	color: rgb(255, 255, 255, 0.8);
  	font-size: 22px;
  	line-height: 98px;
}


.pan_line2 {	
	top: 60px;
	left: 283px;
	width: 262px;
	height: 250px;
	position: absolute;		
	border-radius:16px;
	background-color: #152a3a;
	  
}
.pan_line2 .bg {
	width: 262px;
	height: 103px;
	position: relative;		
	border-radius:16px 16px 0 0;	
	background: rgba(0, 0, 0, 0.5) url("~@/assets/images/yqgh/ydsy.png") no-repeat; 
}


.gytd {
	position: relative;		  	  	
}

.gytd p {
  	font-size: 14px;
  	line-height: 34px;
	position: absolute;		
	color: rgb(255, 255, 255, 0.8);
}

.gytd b {
  	font-size: 22px;
	font-style: oblique;
	position: absolute;		
	color: rgb(255, 255, 255, 1);
}

.gytd b span {
  	font-size: 16px;
}

.gyl_title {
	top: 30px;
	left: 20px;
}
.gyl_value {
	top: 30px;
	left: 130px;
}

.jj_title {
	top: 85px;
	left: 20px;
}
.jj_value {
	top: 85px;
	left: 130px;
}

.color_1{	
	color: #FF5D3B;
}
.color_2{	
	color: #65FF94;
}


.caption3 {	
	top: 295px;
	left: 5px;
	width: 260px;
	height: 24px;
	text-align: left;
  	position: relative;	
	background: url("~@/assets/images/main/imgcaption2.png") no-repeat left; 
}
.caption3 p {	
	left: 23px;
	color: rgb(255, 255, 255, 1);
  	font-size: 15px;
  	line-height: 24px;
  	text-align: left;
  	position: relative;	
}


.table {
	top: 305px;
	margin: 0;
	padding: 0px;
	left: 8px;
	height: 462px;
	width: 534px;
	border-radius:10px;
  	position:relative;
    overflow: hidden;
    border: 1px solid rgb(255, 255, 255, 0.2);
}

.table ul {
	margin: 0;
	padding: 0px;
	left: 0px;
	width: 100%;	
}
.table li {
	margin: 14px 0 8px 0;
	float: left;	
	top: 20px;
	left: 0px;
	width: 133px;	
	height: 135px;
  	position:relative;
  	text-align: center;
	/* margin: 0 0 -2px -2px; */
	color: rgb(255, 255, 255, 0.8);
    /* border: 1px solid rgb(255, 255, 255, 0.4); */
	/* border-right: 2px solid #15dbf5; */
}

.tablevalue {
	color: rgb(255, 255, 255, 0.8);
  	line-height: 16px;
}


.hr2_1{	
	top: 150px;
	left: 0px;	
	height: 0px;
	width: 534px;
	position: absolute;		
	border-bottom: 1px dashed rgb(255, 255, 255, 0.1);
}
.hr2_2{	
	top: 310px;
	left: 0px;	
	height: 0px;
	width: 534px;
	position: absolute;		
	border-bottom: 1px dashed rgb(255, 255, 255, 0.1);
}

.hr3_1{	
	top: 0px;
	left: 0px;	
	height: 462px;
	width: 133px;
	position: absolute;		
	border-right: 1px dashed rgb(255, 255, 255, 0.1);
}
.hr3_2{	
	top: 0px;
	left: 0px;	
	height: 462px;
	width: 266px;
	position: absolute;		
	border-right: 1px dashed rgb(255, 255, 255, 0.1);
}
.hr3_3{	
	top: 0px;
	left: 0px;	
	height: 462px;
	width: 399px;
	position: absolute;		
	border-right: 1px dashed rgb(255, 255, 255, 0.1);
}


</style>