<template>

	<div class="module">		
		<div class="caption">
			<p>实时路况</p>
		</div>

		<div id="cesiumContainer1" class="pan_bmap">	
			
		</div>
	</div>
  
</template>


<script>

    export default {        
        mounted (){
            
            var map = new BMap.Map("cesiumContainer1");
            map.centerAndZoom(new BMap.Point(119.146795,25.475846), 12); 
            map.enableScrollWheelZoom(true);
            
            var traffic = new BMap.TrafficLayer();
            map.addTileLayer(traffic);  
        },        
    }

</script>


<style>

.pan_bmap {	
	top: 20px;
	left: 10px;
	width: 534px;
	height: 430px;
	/* border-radius:10px; */    
  	position:relative;
	background-color: #152a3a;	
}

/* 去除百度地图的logo */
.BMap_cpyCtrl {
    display:none;
}
.anchorBL{
    display:none;
}

</style>