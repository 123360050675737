import {request} from './request';


// 园区概述
export function getYqgs(){
  return request({
    url:'/api/v1/yqgh/getyqgs',
  })
}

// 园区配置
export function getYqgh(){
  return request({
    url:'/api/v1/yqgh/getyqgh',
  })
}

// 园区年度数据
export function getYqyear(yqid){
  return request({
    url:'/api/v1/yqgh/getyqyear?yqid='+yqid,
  })
}

// 园区漫游数据
export function getYqwalk(yqid){
  return request({
    url:'/api/v1/yqgh/getyqwalk?yqid='+yqid,
  })
}


// 问答
export function getQuestion(qtype){
  return request({
    url:'/api/v1/qa/getquestion?qtype='+qtype,
  })
}



