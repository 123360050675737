<template>
		
    <div id="cesiumContainer">
        
    </div>

</template>


<script>
import { MapInit, MapViewInit, MapAddDom_Tianditu, MapAddDem, MapDebugCamera, MapFlyToDuration, MapAdd3DTiles, MapAddTiltPhoto } from './mapbase';
import { getYqgh, getYqwalk } from '../../network/yqgh';
import { getMedia, getCode } from '../../network/media';
import { get3dTiles, getTiltphoto } from '../../network/gis';

export default {
    data () {
        return {
            viewer :'',
            tileset: '',

			gisDemSetting: {},

            // 倾斜摄影
			gisSetting: {},
            gis3dTilesData: [],
            gisTiltphotoData: [],


			yqData: [
				{
					"id": "0",
					"name": "核心区",
					"target": "综合生产服务配套协同创新基地",
					"area": "14.08",
					"quantity": "167",
					"people": "43100",
					"turnover": "640.9",
					"detail": ""
				},
				{
					"id": "1",
					"name": "新涵园",
					"target": "“产学研用”一体化电子信息产业基地",
					"area": "1.4",
					"quantity": "10",
					"people": "460",
					"turnover": "44.97",
					"detail": ""
				},
				{
					"id": "2",
					"name": "城北园",
					"target": "高端装备制造产业基地",
					"area": "4.57",
					"quantity": "12",
					"people": "3120",
					"turnover": "43.06",
					"detail": ""
				},
				{
					"id": "3",
					"name": "食品园",
					"target": "绿色工业旅游食品产业基地",
					"area": "9.21",
					"quantity": "2",
					"people": "1910",
					"turnover": "51.74",
					"detail": ""
				},
				{
					"id": "4",
					"name": "滨海园",
					"target": "军民融合示范基地",
					"area": "16.31",
					"quantity": "3",
					"people": "460",
					"turnover": "0.63",
					"detail": ""
				}
			],					// 园区数据

            arrYQWalk: [],      // 园区漫游数组

            // 漫游
            walk_yqid: 0,
            walk_index: 0,

        }
    },
    
    mounted (){
        
        XE.ready().then(this.init); 
        
    },
    
    methods: {
        
        init() {
                
            // 
            this.earth = new XE.Earth('cesiumContainer');
            this.viewer = this.earth.czm.viewer;
            window.viewer = this.viewer;
            MapViewInit(this.viewer);

            // 影像和高程
            MapAddDom_Tianditu(this.viewer);
            this.initGisDem();



            let that = this;
			getYqgh().then(res=>{
				that.yqData = res.data;	
								
                for (var i=0; i<that.yqData.length; i++) {
                    let yqid = that.yqData[i].id;
			        getYqwalk(yqid).then(res1=>{                       
                        that.arrYQWalk[yqid] = res1.data;                       
                    })
                }

			})



                

            MapFlyToDuration(this.viewer, 1, 119.35072363592676, 26.083644838944874, 3601.6125288002518, 0, -90, 0);


            // 三维建筑模型
            this.init3DTiles();


            // cesium 左键点击事件
            var handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                var pick = viewer.scene.pick(movement.position);
                MapDebugCamera(that.viewer, movement);                // 摄像机信息
                
                // if (Cesium.defined(pick) && (pick.id.id === 'id')) {
                if (Cesium.defined(pick) ) {                // 点击实体 

                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);



            // 同步地图消息(和小地图互动)   
            this.viewer.entities.add({
                position : Cesium.Cartesian3.fromDegrees(0, 0),
                label : {
                    text : new Cesium.CallbackProperty(function(){
                        that.syncViewer();
                        return "";
                    }, true)
                }
            });


            // 添加园区图
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/qt/yards0.geojson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#ECB22F"),
                        fill: Cesium.Color.fromCssColorString("#E9A82A").withAlpha(0.2),
                        strokeWidth: 3,
                    }
                )
            );
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/qt/yards1.geojson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#FF5D3B"),
                        fill: Cesium.Color.fromCssColorString("#FF5D3B").withAlpha(0.2),
                        strokeWidth: 3,
                    }
                )
            );
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/qt/yards2.geojson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#65FF94"),
                        fill: Cesium.Color.fromCssColorString("#65FF94").withAlpha(0.2),
                        strokeWidth: 3,
                    }
                )
            );
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/qt/yards3.geojson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#1AFFEF"),
                        fill: Cesium.Color.fromCssColorString("#03B3A7").withAlpha(0.2),
                        strokeWidth: 3,
                    }
                )
            );
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/qt/yards4.geojson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#6BD8FB"),
                        fill: Cesium.Color.fromCssColorString("#9BF5ED").withAlpha(0.2),
                        strokeWidth: 3,
                    }
                )
            );
            
            


            // loadAllYards:function(e){
            // 	var t=this,
            // 	i=g.sysService+"/fjsmart3d/yards.geojson";
                
            // 	Cesium.GeoJsonDataSource.load(i).then(
            // 		function(i){
            // 			t.parkPlanningData=i.entities.values;
            // 			var r=void 0,a=void 0;
            // 			setTimeout(function(){a="14.09平方公里",r=Cesium.Color.AQUA;var i=t.parkPlanningData[3];t.loadPlanningPolygon(i,e,r,a)},3e3),
            // 			setTimeout(function(){a="1.4平方公里",r=Cesium.Color.STEELBLUE;var i=t.parkPlanningData[0];t.loadPlanningPolygon(i,e,r,a)},4e3),
            // 			setTimeout(function(){a="4.57平方公里",r=Cesium.Color.CORAL;var i=t.parkPlanningData[4];t.loadPlanningPolygon(i,e,r,a)},5e3),
            // 			setTimeout(function(){a="5.12平方公里",r=Cesium.Color.GOLD;var i=t.parkPlanningData[2];t.loadPlanningPolygon(i,e,r,a)},6e3),
            // 			setTimeout(function(){a="56平方公里",r=Cesium.Color.MEDIUMSPRINGGREEN;var i=t.parkPlanningData[1];t.loadPlanningPolygon(i,e,r,a)},7e3)
            // 		}
            // 	)
            // },


            // 文字
            
            
            this.viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(119.34981603153649, 26.08682299432216,2.61),
                // point: {
                //     color: Cesium.Color.RED,    //点位颜色
                //     pixelSize: 10                //像素点大小
                // },
                label : {
                    text : 'B地块',
                    font : '14pt Source Han Sans CN',    //字体样式
                    fillColor:Cesium.Color.BLACK,        //字体颜色
                    backgroundColor:Cesium.Color.AQUA,    //背景颜色
                    showBackground:true,                //是否显示背景颜色
                    style: Cesium.LabelStyle.FILL,        //label样式
                    outlineWidth : 2,                    
                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,//垂直位置
                    horizontalOrigin :Cesium.HorizontalOrigin.CENTER,//水平位置
                    pixelOffset:new Cesium.Cartesian2(10,0)            //偏移
                }
            });

            this.viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(119.34829173162262, 26.089700928657145,2.61),
                // point: {
                //     color: Cesium.Color.RED,    //点位颜色
                //     pixelSize: 10                //像素点大小
                // },
                label : {
                    text : 'A地块',
                    font : '14pt Source Han Sans CN',    //字体样式
                    fillColor:Cesium.Color.BLACK,        //字体颜色
                    backgroundColor:Cesium.Color.AQUA,    //背景颜色
                    showBackground:true,                //是否显示背景颜色
                    style: Cesium.LabelStyle.FILL,        //label样式
                    outlineWidth : 2,                    
                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,//垂直位置
                    horizontalOrigin :Cesium.HorizontalOrigin.CENTER,//水平位置
                    pixelOffset:new Cesium.Cartesian2(10,0)            //偏移
                }
            });
            
            this.viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(119.35893326346009, 26.079365163214163),
                // point: {
                //     color: Cesium.Color.RED,    //点位颜色
                //     pixelSize: 10                //像素点大小
                // },
                label : {
                    text : 'C地块',
                    font : '14pt Source Han Sans CN',    //字体样式
                    fillColor:Cesium.Color.BLACK,        //字体颜色
                    backgroundColor:Cesium.Color.AQUA,    //背景颜色
                    showBackground:true,                //是否显示背景颜色
                    style: Cesium.LabelStyle.FILL,        //label样式
                    outlineWidth : 2,                    
                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,//垂直位置
                    horizontalOrigin :Cesium.HorizontalOrigin.CENTER,//水平位置
                    pixelOffset:new Cesium.Cartesian2(10,0)            //偏移
                }
            });
                
        },

        
        initGisDem() {
            
			// Gis配置（16）
			getCode("gis_set", "16").then(res=>{
				this.gisDemSetting = res.data[0];					
                
                console.log(this.gisDemSetting.value1);
                // MapAddDem(this.viewer, "http://39.107.47.88:8015/fjsmart3d/3dtiles/dem/fujian/quansheng");
                MapAddDem(this.viewer, this.gisDemSetting.value1);
			})

        },


        // 添加三维建筑模型
        init3DTiles() {

			// Gis配置（15）
			getCode("gis_set", "15").then(res=>{
				this.gisSetting = res.data[0];					


                // // 三维模型
                // get3dTiles().then(res=>{
                //     this.gis3dTilesData = res.data;	

                //     for (var i=0; i<this.gis3dTilesData.length; i++) {
                //         if (this.gis3dTilesData[i].isshow == 0) {
                //             continue;
                //         }

                //         var url = this.gisSetting.value1 + '3dtiles/' + this.gis3dTilesData[i].name + '/tileset.json';

                //         var strarr_material = this.gis3dTilesData[i].arr_material.split(",");
                //         var arr_material = strarr_material.map((value)=>{
                //             return  parseFloat(value);
                //         })


                //         var strarr_transform = this.gis3dTilesData[i].arr_transform.split(",");
                //         var arr_transform = strarr_transform.map((value)=>{
                //             return  parseFloat(value);
                //         })

                //         MapAdd3DTiles(this.viewer, url, arr_transform, arr_material);
                //     }                                    
                // })


                // 倾斜摄影
                getTiltphoto().then(res=>{
                    this.gisTiltphotoData = res.data;	

                    for (var i=0; i<this.gisTiltphotoData.length; i++) {
                        if (this.gisTiltphotoData[i].isshow == 0) {
                            continue;
                        }

                        var url = this.gisSetting.value1 + 'tiltphoto/' + this.gisTiltphotoData[i].pathkey + '/tileset.json';
                        var heightoffset = this.gisTiltphotoData[i].heightoffset

                        console.log("getTiltphoto: " + i + "  " + url  + "  " + heightoffset)
                        
                        MapAddTiltPhoto(this.viewer, url, heightoffset);                        
                        // MapAddTiltPhoto(this.viewer, "http://127.0.0.1:8015/tiltphoto/2/tileset.json", -17.0);
                    }                                    
                })

			})
        },


        // 选择园区
        selectyq(index) {	
            this.debugCamera()
            
            switch(index) {
                case "0":
                    this.showHXQ();
                    break;
                case "1":
                    this.showXHY();
                    break;
                case "2":
                    this.showCBY();
                    break;
                case "3":
                    this.showSPY();
                    break;
                case "4":
                    this.showBHY();
                    break;
                default:

            }   

        },

        // 清空viewer所有内容
        viewerremoveAll() {
            this.viewer.dataSources.removeAll();
            this.viewer.entities.removeAll();
            this.viewer.scene.postProcessStages.removeAll();

            this.debugCamera()
        },

        debugCamera() {
            var position = this.viewer.scene.camera.position            
            var cartographic = Cesium.Cartographic.fromCartesian(position);
            var x = Cesium.Math.toDegrees(cartographic.longitude);
            var y = Cesium.Math.toDegrees(cartographic.latitude);
            var z = cartographic.height;
            console.log("camera: (%f, %f, %f)", x, y, z)
        },


        
        // 同步地图
        syncViewer() {				
            
            this.$parent.syncViewer();
        },



        // 0.核心区
        showHXQ() {
            this.viewerremoveAll();
            // this.initFlyTo(119.14936954817179, 25.473900827559582, 9696.686849937958);
            MapFlyToDuration(this.viewer, 1, 119.14627148015124, 25.40628813300637, 4069.094811950268, 0, -30, 0);

            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/qt/yards0.geojson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#ECB22F"),
                        fill: Cesium.Color.fromCssColorString("#E9A82A").withAlpha(0.03),
                        strokeWidth: 3,
                    }
                )
            );

            this.walk_yqid = 0;
            this.walk_index = 0;

        },

        // 1.新涵园
        showXHY() {
            this.viewerremoveAll();
            // this.initFlyTo(119.10407509678394, 25.49339287809681, 2943.9975916829007);
            MapFlyToDuration(this.viewer, 1, 119.10246369203146, 25.475948855097975, 1199.2018575536358, 0, -30, 0);
            
            this.viewer.dataSources.add(
                Cesium.GeoJsonDataSource.load(
                    "model/qt/yards1.geojson",
                    {
                        stroke: Cesium.Color.fromCssColorString("#FF5D3B"),
                        fill: Cesium.Color.fromCssColorString("#FF5D3B").withAlpha(0.03),
                        strokeWidth: 3,
                    }
                )
            );

            this.walk_yqid = 1;
            this.walk_index = 0;

        },
        
        // 2.城北园
        showCBY() {
            this.viewerremoveAll();
            // this.initFlyTo(119.13863302977609, 25.493325869481705, 5630.7775088888);
            MapFlyToDuration(this.viewer, 1, 119.13780548360774, 25.462470706893384, 2187.830522758155, 0, -30, 0);


			this.viewer.dataSources.add(
				Cesium.GeoJsonDataSource.load(
					"model/qt/yards2.geojson",
					{
                        stroke: Cesium.Color.fromCssColorString("#65FF94"),
                        fill: Cesium.Color.fromCssColorString("#65FF94").withAlpha(0.03),
						strokeWidth: 3,
					}
				)
			);

            this.walk_yqid = 2;
            this.walk_index = 0;

        },
        
        // 3.食品园
        showSPY() {
            this.viewerremoveAll();
            // this.initFlyTo(119.13564863140182, 25.427741421124065, 9878.948382166787);
            MapFlyToDuration(this.viewer, 1, 119.13267482764537, 25.385702418971857, 2416.3581748399797, 0, -30, 0);


			this.viewer.dataSources.add(
				Cesium.GeoJsonDataSource.load(
					"model/qt/yards3.geojson",
					{
                        stroke: Cesium.Color.fromCssColorString("#1AFFEF"),
                        fill: Cesium.Color.fromCssColorString("#03B3A7").withAlpha(0.03),
						strokeWidth: 3,
					}
				)
			);

            this.walk_yqid = 3;
            this.walk_index = 0;

        },
        
        // 4.滨海园
        showBHY() {
            this.viewerremoveAll();
            // this.initFlyTo(119.19102514436098, 25.45315695753712, 11255.226356250689);
            MapFlyToDuration(this.viewer, 1, 119.18941131479858, 25.376541073828545, 5202.031148997445, 0, -30, 0);

			this.viewer.dataSources.add(
				Cesium.GeoJsonDataSource.load(
					"model/qt/yards4.geojson",
					{
                        stroke: Cesium.Color.fromCssColorString("#6BD8FB"),
                        fill: Cesium.Color.fromCssColorString("#9BF5ED").withAlpha(0.03),
						strokeWidth: 3,
					}
				)
			);

            this.walk_yqid = 4;
            this.walk_index = 0;

        },


        // 开始漫游
        startWalk() {
            this.walk_index = 0;
            this.WalkStep();
        },
        
        // 停止漫游
        stopWalk() {
            this.walk_index = 9999;            
        },

        
        flyToDuration(viewer, duration, longitude, latitude, height, heading, pitch, roll){
            
            // // 将经纬度转换为世界坐标           
            // var target = Cesium.Cartesian3.fromDegrees(longitude, latitude, height);
            // var offset = new Cesium.Cartesian3(-70.06, -68.64, 6.0908)
            // viewer.scene.camera.lookAt(target, offset);

            // 飞入
            viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),   
                orientation: {
                    heading: Cesium.Math.toRadians(heading),
                    pitch: Cesium.Math.toRadians(pitch),
                    roll: Cesium.Math.toRadians(roll)
                },
                complete: function callback() {
                    // 定位完成之后的回调函数
                },
                
                easingFunction: Cesium.EasingFunction.LINEAR_NONE,
                duration: duration // 设置飞行持续时间，默认会根据距离来计算
            });
        },

        // 漫游步骤
        WalkStep() {            
            console.log("======WalkStep: "+ this.walk_index + " " + Date());

            let arrwalk = this.arrYQWalk[this.walk_yqid];

            console.log("arrwalk.length=" + arrwalk.length + "; this.walk_index=" + this.walk_index);
            if (this.walk_index >= arrwalk.length) {
                console.log("stop arrwalk.length=" + arrwalk.length + "; this.walk_index=" + this.walk_index);   
                return;
            }
            var index = this.walk_index++;

            var duration = parseFloat(arrwalk[index].duration);

            var strarrcamera = arrwalk[index].camera.split(",");
            var arrcamera = strarrcamera.map((value)=>{
                return parseFloat(value);
            })

            if (arrcamera.length < 6) {
                console.log("arrcamera.length("+ arrcamera.length +") < 6");
                return;
            }
            
            console.log("WalkFlyTo: %d, %f, %f, %f, %f, %f, %f", index, duration, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);


            // MapFlyToDuration(this.viewer, duration, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);
            this.flyToDuration(this.viewer, duration, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);

            
            setTimeout(() => {
                this.WalkStep();
            }, duration*1000);

        },


        // 漫游(第3个点后就不动了)
        WalkFlyToDuration(){
            
            let arrwalk = this.arrYQWalk[this.walk_yqid];

            console.log("arrwalk.length=" + arrwalk.length + "; this.walk_index=" + this.walk_index);
            if (this.walk_index >= arrwalk.length) {
                console.log("stop arrwalk.length=" + arrwalk.length + "; this.walk_index=" + this.walk_index);   
                return;
            }
            var index = this.walk_index++;

            var duration = parseFloat(arrwalk[index].duration);

            var strarrcamera = arrwalk[index].camera.split(",");
            var arrcamera = strarrcamera.map((value)=>{
                return parseFloat(value);
            })

            if (arrcamera.length < 6) {
                console.log("arrcamera.length("+ arrcamera.length +") < 6");
                return;
            }
            
            console.log("WalkFlyTo: %d, %f, %f, %f, %f, %f, %f", index, duration, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);

            var that = this;
            this.viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(arrcamera[0], arrcamera[1], arrcamera[2]),   
                orientation: {
                    heading: Cesium.Math.toRadians(arrcamera[3]),
                    pitch: Cesium.Math.toRadians(arrcamera[4]),
                    roll: Cesium.Math.toRadians(arrcamera[5])
                },
                complete: function callback() {     // 定位完成之后的回调函数
                    console.log("next walk: " + that.walk_index);
                    that.WalkFlyToDuration();
                },
                duration: duration // 设置飞行持续时间，默认会根据距离来计算
            });
        },



        // 初始化飞入位置
        initFlyTo(initialLon, lat, height) {
            
            // // 将经纬度转换为世界坐标           
            // var target = Cesium.Cartesian3.fromDegrees(initialLon, lat, height);
            // var offset = new Cesium.Cartesian3(-70.06, -68.64, 6.0908)
            // this.viewer.scene.camera.lookAt(target, offset);

            // 飞入
            this.viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(initialLon, lat, height),   
                orientation: {
                    heading: Cesium.Math.toRadians(0),
                    pitch: Cesium.Math.toRadians(-90),
                    roll: Cesium.Math.toRadians(0)
                },
                complete: function callback() {
                    // 定位完成之后的回调函数
                },
                duration: 1 // 设置飞行持续时间，默认会根据距离来计算
            });

        },
        
    }               

}

</script>


<style scoped>


.navmanyou {
    
    z-index: 100;
    
    top: 124px;
    left: 523px;
    width: 453px;
    height: 76px;

    position:absolute;
	background: url("~@/assets/images/qwys/mapmenu.png") no-repeat; 	

}

.navmanyou ul {   
    top: 20px;
    left: 80px; 
    position:absolute;
}

.navmanyou li {    
	float: left;
    width: 150px;
	cursor: pointer;

    text-align: center;
}

.navmanyou li p{    
    margin: 0 10px 0 10px;
    top: 10px;
	font-size: 16px;
	line-height: 30px;	
	text-align: center;

    
    border-radius: 15px;
    background-color: rgb(255, 255, 255, 0.02);	

	color: rgb(255, 255, 255, 0.8);	
}

.navmanyou li .currentyq{    
    background-color: rgb(255, 255, 255, 0.2);	
	color: rgb(255, 255, 255, 0.8);	
}



</style>