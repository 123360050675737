<template>

	<div class="module">
		<div class="caption">
			<p>实时监控</p>
		</div>
		
		<div id="video-container" class="pan">	
			
		</div>

	</div>
  
</template>



<script>
import EZUIKit from "ezuikit-js";
import { getEzuikitAccessToken } from '../../network/camera';

export default {
    data() 
	{ 
		
	}, 
    mounted() 
	{ 
		this.init();
	},

	methods: {

		init() {

			getEzuikitAccessToken(this.yqid).then(res=>{
				
				var token = res.data.accessToken;

				var player =  new EZUIKit.EZUIKitPlayer({
					autoplay: true,
					id: "video-container",
					// url: "ezopen://open.ys7.com/G17034342/1.hd.live",
					url: "ezopen://open.ys7.com/G17033844/1.hd.live",
					accessToken: token,
					template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
					audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
					width: 470,			// 原始比例 1000:562   
					height: 264         
				});			

			})

		},

	}
}
</script>



<style scoped>

.pan {	
	top: 20px;
	left: 10px;		
	width: 534px;
	height: 264px;
  	position: relative;
  	overflow: hidden;
}

</style>