<template>

	<div id="cesiumContainer1"  class="module">		
		<!-- <div id="cesiumContainer1" class="pan">	
			
		</div> -->
	</div>
  
</template>



<script>

    export default {
        data () {
            return {
                viewer :'',
                tileset: '',
            }
        },
        
        mounted (){
            Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MTQzMWRhZS04ZDkyLTRkMmUtYmYyZi1mOGY2ZmRkNDgyZjAiLCJpZCI6MzkwNDIsImlhdCI6MTYwNzA3MjQ1Nn0.s8_tJUR4Y8Rd8spunTJxrh0GrzNbVGYRyXyh4f9eWDs'

            // 创建viewer实例
            this.viewer = new Cesium.Viewer('cesiumContainer1', {
                // 需要进行可视化的数据源的集合
                animation: false, // 是否显示动画控件
                shouldAnimate: true,  // 当动画控件出现，用来控制是否通过旋转控件，旋转场景
                homeButton: false, // 是否显示Home按钮
                fullscreenButton: false, // 是否显示全屏按钮
                baseLayerPicker: false, // 是否显示图层选择控件
                navigationInstructionsInitiallyVisible: false,  // 帮助按钮，初始化的时候是否展开
                geocoder: false, // 是否显示地名查找控件
                timeline: false, // 是否显示时间线控件
                sceneModePicker: false, // 是否显示投影方式控件
                navigationHelpButton: false, // 是否显示帮助信息控件
                infoBox: false, // 是否显示点击要素之后显示的信息
                requestRenderMode: true, // 启用请求渲染模式
                scene3DOnly: false, // 每个几何实例将只能以3D渲染以节省GPU内存
                sceneMode: 3, // 初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
                fullscreenElement: document.body, // 全屏时渲染的HTML元素 暂时没发现用处

            })

            // window.viewer = this.viewer;

            // 去除版权信息、大气层、天空盒、背景色
            this.viewer._cesiumWidget._creditContainer.style.display = 'none'                  
            // this.viewer.scene.skyAtmosphere.show=false; //关闭大气层     
            // this.viewer.scene.skyBox.show = false;                     
            // this.viewer.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0);


            // this.initTianditu();
            // this.initFlyTo(119.1615990784875, 25.461432088988435, 20829.16202385373);


            // this.add3DModel();
            // this.add3DTiles();





            // const handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
            // handler.setInputAction(function (click) {
            //     const pick = self.viewer.scene.pick(click.position);
            //     if (pick && pick.id) {
            //         console.log(pick.id.id + pick.id.name);
            //         window.open(pick.id.description);  
            //     }
            // }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            

        },
        
        methods: {
            
			// 同步地图
			syncViewer() {			
                    
                let cartographic = Cesium.Cartographic.fromCartesian(window.viewer.camera.position);
                let x = Cesium.Math.toDegrees(cartographic.longitude);
                let y = Cesium.Math.toDegrees(cartographic.latitude);
                // let z = cartographic.height;
                let z = 20000;
                            
				this.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(x, y, z),   
					orientation: {
                        heading: Cesium.Math.toRadians(0),
                        pitch: Cesium.Math.toRadians(-90),
                        roll: Cesium.Math.toRadians(0)
					},
					duration: 0.0
				});
			},

			// // 同步地图
			// syncViewer() {						
			// 	this.viewer.camera.flyTo({
			// 		destination: window.viewer.camera.position,
			// 		orientation: {
			// 			heading: window.viewer.camera.heading,
			// 			pitch: window.viewer.camera.pitch,
			// 			roll: window.viewer.camera.roll                        
			// 		},
			// 		duration: 0.0
			// 	});
			// },



            //加载天地图
            initTianditu() {
                let token = "6e9cebf98fdd9477585ef2a0d3857163";
                // 服务域名
                let tdtUrl = 'https://t{s}.tianditu.gov.cn/';
                // 服务负载子域
                let subdomains=['0','1','2','3','4','5','6','7'];

                let imageryLayers = this.viewer.imageryLayers;
                // 天地图叠加影像服务
                let imgMap = new Cesium.UrlTemplateImageryProvider({
                    url: tdtUrl + 'DataServer?T=img_w&x={x}&y={y}&l={z}&tk=' + token,
                    subdomains: subdomains,
                    tilingScheme : new Cesium.WebMercatorTilingScheme(),
                    maximumLevel : 18
                });
                imageryLayers.addImageryProvider(imgMap);



				// // 中文注记
				// this.viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
				// 	url: "http://t0.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg",
				// 	layer: "tdtAnnoLayer",
				// 	style: "default",
				// 	format: "image/jpeg",
				// 	tileMatrixSetID: "GoogleMapsCompatible",
				// 	show: false
				// }));


            },


        }

        

    }



</script>





<style scoped>

.pan {	
	top: 10px;
	left: 10px;
	width: 534px;
	height: 533px;
	border-radius:10px;
    z-index: 99;
  	position:relative;
	background-color: #152a3a;	
}


</style>