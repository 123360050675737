<template>  
	<div class="panYqgs">		
    	<YQGS />
	</div>

	<!-- <div class="panQylhqk">		
    	<QYLHQK />
	</div> -->

	<div class="panmap">
		
		<!-- <div class="navmanyou">
			<ul>
				<li><p class="currentyq" @click="startWalk">园区漫游</p></li>
				<li><p @click="stopWalk">停止漫游</p></li>
			</ul>
		</div> -->

		<div class="mapleft"></div>
		<div class="maptop"></div>
		
		<div class="map">
    		<MapYQGH ref="child_mapyqgh" />
		</div>

		<div class="mapright"></div>
		<div class="mapbottom"></div>
	</div>

	<div class="panYqcy">		
    	<YQCY ref="child_yqcy" />
	</div>

	<div class="panCykx">		
    	<CYKX />
	</div>
	
	<div class="panSnzsdb">		
    	<SNZSDB ref="child_snzsdb" />
	</div>
	
	<!-- <div class="panCamera">		
    	<CAMERA ref="child_camera" />
	</div> -->
	
	<!-- <div class="panSmallmap">		
		<BMAP ref="child_smallmap" />
	</div> -->

</template>

<script>
import YQGS from '@/components/yqgh/yqgs.vue'
import QYLHQK from '@/components/yqgh/qylhqk.vue'
import YQCY from '@/components/yqgh/yqcy.vue'
import CYKX from '@/components/yqgh/cykx.vue'
import SNZSDB from '@/components/yqgh/snzsdb.vue'
import CAMERA from '@/components/yqgh/camera.vue'
import SMALLMAP from '@/components/yqgh/smallmap.vue'
import BMAP from '@/components/yqgh/bmap.vue'
import MapApi from '@/components/MapApi.vue'
import MapYQGH from '@/components/map/MapYqgh.vue'

export default {

	components: {
		YQGS,
		QYLHQK,
		YQCY,
		CYKX,
		SNZSDB,
		CAMERA,
		SMALLMAP,
		BMAP,
		MapApi,
		MapYQGH,
	},


	methods: {

		// 选择园区
		selectyq(index) {
			// console.log(index);
			this.$refs.child_yqcy.selectyq(index);
			this.$refs.child_snzsdb.selectyq(index);
			this.$refs.child_mapyqgh.selectyq(index);
			
		},

		
		// 同步地图
		syncViewer() {
			if (this.$refs.child_smallmap != null) {
				// this.$refs.child_smallmap.syncViewer();
			}
			
		},

		
        // 开始漫游
        startWalk() {
			this.$refs.child_mapyqgh.startWalk();
        },
        
        // 停止漫游
        stopWalk() {
			this.$refs.child_mapyqgh.stopWalk();      
        },


	}

}
</script>

<style scoped>

.panmap {		
	top: 0px;
	left: 582px;
	width: 1465px;
	height: 944px;
	text-align: center;
  	position: relative;	
  	overflow: hidden;
}


.panYqgs {	
	top: 79px;
	left: 30px;
	width: 554px;
	height: 837px;
	text-align: center;
	position: absolute;		
	z-index: 10;
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panQylhqk {	
	top: 79px;
	left: 599px;
	width: 554px;
	height: 837px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}

.panYqcy {	
	top: 79px;
	right: 30px;
	width: 554px;
	height: 269px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}


.panCykx {	
	/* top: 362px; */
	top: 645px;
	right: 30px;
	width: 554px;
	/* height: 553px; */
	height: 270px;
	text-align: center;
	position: absolute;			
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panSnzsdb {	
	top: 362px;
	right: 30px;
	width: 554px;
	height: 269px;
	text-align: center;
	position: absolute;			
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panCamera {	
	top: 79px;
	left: 3256px;
	width: 554px;
	height: 329px;
	text-align: center;
	position: absolute;			
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panSmallmap {	
	top: 422px;
	left: 3256px;
	width: 554px;
	height: 493px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}




.navmanyou {
    
    z-index: 100;
    
    top: 100px;
    left: 523px;
    width: 453px;
    height: 76px;

    position:absolute;
	background: url("~@/assets/images/qwys/mapmenu.png") no-repeat; 	

}

.navmanyou ul {   
    top: 20px;
    left: 80px; 
    position:absolute;
}

.navmanyou li {    
	float: left;
    width: 150px;
	cursor: pointer;

    text-align: center;
}

.navmanyou li p{    
    margin: 0 10px 0 10px;
    top: 10px;
	font-size: 16px;
	line-height: 30px;	
	text-align: center;

    
    border-radius: 15px;
    background-color: rgb(255, 255, 255, 0.02);	

	color: rgb(255, 255, 255, 0.8);	
}

.navmanyou li .currentyq{    
    background-color: rgb(255, 255, 255, 0.2);	
	color: rgb(255, 255, 255, 0.8);	
}

</style>