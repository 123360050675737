<template>

	<div class="module">
		<div class="caption">
			<p>三年增速对比（{{ yqname }}）</p>
		</div>
		
		<div class="pan">				
			<ul>
				<li>			
					<div class="pan_chart" ref="chart1">
					</div>
				</li>
				<li>					
					<div class="pan_chart" ref="chart2">
					</div>
				</li>
			</ul>
		</div>

	</div>
  
</template>



<script>
import * as Echarts from 'echarts';
import { getYqyear } from '../../network/yqgh';

export default {
    data() 
	{ 
		return { 
			yqid: '0',
			yqname: '',

			chart1: null,
			option1: null,

			chart2: null,
			option2: null
		} 	
	}, 
    mounted() 
	{ 
		this.init();
	},

	methods: {

		// 选择园区
		selectyq(index) {			
			this.yqid = index;
			this.refresh();
		},

		init() {
			//1.初始化
			this.chart1 = Echarts.init(this.$refs.chart1);
			this.chart2 = Echarts.init(this.$refs.chart2);	
			
			//2.配置数据
			this.option1 = {
						
				grid: {
					left: "3%",
					right: "2%",
					bottom: "0%",
					top: "20%",
					containLabel: !0
				},
				xAxis: {
					data: ["2018", "2019", "2020"],
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
				},
				yAxis: {
					name: "企业（家）",
					nameTextStyle: {
						padding: [0, 0, 0, 30],
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 15
					},			
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					},
					// interval: 50
				},
				series: [{
					type: "bar",
					barWidth: 30,
					itemStyle: {
						normal: {
							barBorderRadius:[5, 5, 0, 0],
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: "#00CCF7"
							},
							{
								offset: 1,
								color: "#93FFE5"
							}], !1)
						}
					},
					label: {
						normal: {
							show: !0,
							fontSize: 14,
							color: "#c8d228",
							position: "top"
						}
					},
					data: [134, 145, 167]
				}]
			};
			
			this.option2 = {
						
				grid: {
					left: "3%",
					right: "2%",
					bottom: "0%",
					top: "20%",
					containLabel: !0
				},
				xAxis: {
					data: ["2018", "2019", "2020"],
					axisLine: {
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.8)',					
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12
					},
				},
				yAxis: {
					name: "产值（亿）",
					nameTextStyle: {
						padding: [0, 0, 0, 30],
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 15
					},			
					axisLine: {
						show: true,
						lineStyle: {					
							width: 1,		
							color: 'rgba(255, 255, 255, 0.3)',
						}
					},
					axisLabel: {
						color: 'rgba(255, 255, 255, 0.8)',
						fontSize: 12,
						formatter: '{value}'
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							width: 1,
							color: 'rgba(255, 255, 255, 0.1)',
						}
					},
					// interval: 200
				},
				series: [{
					type: "bar",
					barWidth: 30,
					itemStyle: {						
						normal: {
							barBorderRadius:[5, 5, 0, 0],
							color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
								offset: 0,
								color: "#FF5D3B"
							},
							{
								offset: 1,
								color: "#FF7E3B"
							}], !1)
						}
					},
					label: {
						normal: {
							show: !0,
							fontSize: 14,
							color: "#c8d228",
							position: "top"
						}
					},
					data: [598, 621, 640]
				}]
			};

			// 3.传入数据
			this.chart1.setOption(this.option1);
			this.chart2.setOption(this.option2);


			this.refresh();
		},

		refresh() {			

			getYqyear(this.yqid).then(res=>{
								
				this.yqname = res.data[0].yqname;

				this.option1.xAxis.data = [];
				this.option1.series[0].data = [];
				this.option2.xAxis.data = [];
				this.option2.series[0].data = [];

				for (var i=0; i<res.data.length; i++) {
					this.option1.xAxis.data.push(res.data[i].year);
					this.option1.series[0].data.push(res.data[i].quantity);

					this.option2.xAxis.data.push(res.data[i].year);
					this.option2.series[0].data.push(res.data[i].turnover);
				}

				this.chart1.clear();
				this.chart1.setOption(this.option1);

				this.chart2.clear();
				this.chart2.setOption(this.option2);

			})
		}
	}
}
</script>



<style scoped>


.pan {	
	top: 3px;
	left: 10px;
	width: 534px;
	height: 204px;
  	position:relative;
	/* background-color: brown; */
}

.pan ul {
	margin: 0;
	padding: 14px;
	left: 0px;

}
.pan li {
	float: left;
	width: 250px;
	height: 204px;
	/* background-color: red; */
}

.pan_chart {	
	width: 100%;
	height: 100%;
}



</style>